import {NgModule} from '@angular/core';
import {AsyncPipe, CommonModule, DatePipe, NgIf, NgTemplateOutlet, TitleCasePipe, UpperCasePipe} from '@angular/common';
import {SpinnerComponent} from './spinner/spinner.component';
import {RouterLink} from "@angular/router";
import {NgLetDirective} from "./ng-let.directive";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle,
  MatDateRangeInput,
  MatDateRangePicker
} from "@angular/material/datepicker";
import {MatIcon} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIconButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {MatSidenavModule} from "@angular/material/sidenav";
import {DateNavigatorComponent} from './date-navigator/date-navigator.component';
import { GlobalLoadingComponent } from './global-loading/global-loading.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    NgLetDirective,
    DateNavigatorComponent,
    GlobalLoadingComponent,
  ],
  exports: [
    SpinnerComponent,
    NgLetDirective,
    DateNavigatorComponent,
    GlobalLoadingComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    MatFormField,
    MatLabel,
    MatDateRangeInput,
    MatDatepickerToggle,
    MatDateRangePicker,
    MatIcon,
    ReactiveFormsModule,
    TitleCasePipe,
    AsyncPipe,
    UpperCasePipe,
    MatTooltip,
    FormsModule,
    DatePipe,
    MatDatepickerInput,
    MatDatepicker,
    MatIconButton,
    MatInput,
    NgTemplateOutlet,
    NgIf,
    FaIconComponent,
    MatSidenavModule,

  ]
})
export class ComponentsModule {
}
