import { Component } from '@angular/core';

@Component({
  selector: 'app-global-loading',
  templateUrl: './global-loading.component.html',
  styleUrl: './global-loading.component.scss'
})
export class GlobalLoadingComponent {

  constructor() {
  }
}
