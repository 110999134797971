<div class="h-full w-full absolute top-0 left-0 flex justify-center items-center flex-column"
     style="background: rgba(0,0,0,0.50); z-index: 99999;">
  <div class="sk-chase">
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
    <div class="sk-chase-dot"></div>
  </div>
  <div class="text-white mt-8"><b>Loading ...</b>
  </div>
</div>
