import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import moment from 'moment';
import 'moment/locale/it';
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-date-navigator',
  templateUrl: './date-navigator.component.html',
  styleUrls: ['./date-navigator.component.scss']
})
export class DateNavigatorComponent implements OnInit {
  @Output() dateChanged = new EventEmitter<moment.Moment>();
  form: FormGroup;
  _currentDate = moment();
  constructor(private fb: FormBuilder) {

    this.form = this.fb.group({
      date: this._currentDate.format('YYYY-MM-DD')
    });

    this.form.get('date').valueChanges.subscribe(x=>{

      this.currentDate = x;
      this.emitDateChange();
    })
  }

  ngOnInit(): void {
    moment.locale('it');  // Imposta la localizzazione italiana
    this.emitDateChange();
  }

  changeDate(days: number): void {
    this._currentDate.add(days, 'days');
    this.form.get('date').setValue(this._currentDate.format('YYYY-MM-DD'))
  }


  get currentDate(): string {
    return this._currentDate.format('YYYY-MM-DD');
  }

  set currentDate(value: Date) {

    this._currentDate = moment(value);
  }

  private emitDateChange(): void {
    this.dateChanged.emit(this._currentDate);
  }
}
