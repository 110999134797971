const REALM = 'triton'
const CLIENT_ID = 'backoffice'
export const environment = {
  be_url: 'https://backoffice.triton.seaportsolution.com/api',
  keycloak: {
    url: 'https://auth.triton.seaportsolution.com',
    realm: `${REALM}`,
    clientId: `${CLIENT_ID}`,
  },

};
